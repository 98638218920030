<template>
  <section class="body">
    <section class="banner">
      <div class="summary">
        <h3>汽车智能数字钥匙</h3>
        <ul class="tags">
          <li>金融级钥匙安全模块</li>
          <li>多协议兼容</li>
          <li>全自动标定部署工具</li>
          <li>人工智能定位算法</li>
          <li>蓝牙loT设备接入</li>
        </ul>
      </div>
      <img class="img" src="../../assets/vbd/key/banner.png" width="860" height="830" />
    </section>

    <div class="layout">
      <h3>汽车智能数字钥匙</h3>
      <div class="content">
        <div class="cover">
          <img class="img" src="../../assets/vbd/key/cover.png" width="290" height="400" />
        </div>
        <div class="col">
          <div class="item">
            <h4>金融级钥匙安全模块</h4>
            <p>符合IFAA身份安全标准，全面保障保证蓝牙数字钥匙的分发鉴权</p>
          </div>
          <div class="item">
            <h4>蓝牙IOT设备接入</h4>
            <p>矩阵提供整套IOT接入协议，提供与智联网汽车连接的标准化方案。实现车辆与外围硬件互联互通，为用户打造原厂原装原生态的车内体验</p>
            <p class="tip">（已接入智能硬件：智能充电桩无感充电，智慧车库无感解锁）</p>
          </div>
          <div class="item">
            <h4>多协议兼容</h4>
            <p>矩阵蓝牙钥匙模块支持主流钥匙协议CCC、 ICCE、ICCOA及矩阵自主研发的数字钥匙协议，通过建立统一的Framework层，方便移动设备应用开发与各种协议车型的对接</p>
          </div>
        </div>
        <div class="col">
          <div class="inset">
            <img class="img" src="../../assets/vbd/key/inset.png" width="300" height="270" />
          </div>
          <div class="item">
            <h4>全自动标定部署工具</h4>
            <p>矩阵开发了全套完整的上位机标定采集工具，以及自动标定机器人，助力客户实现车型快速适配部署</p>
          </div>
          <div class="item">
            <h4>人工智能定位算法</h4>
            <p>采用深度学习推理算法，实现高精度定位，降低车主手机蓝牙硬件差异带来的整车PEPS功能体感的差异</p>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: "KEY",
};
</script>

<style lang="scss" scoped>
.layout {
  padding-top: 180px;
  padding-bottom: 180px;
  max-width: 1160px;

  h3 {
    font-size: 36px;
    line-height: 46px;
  }
}


.banner {
  display: flex;
  padding-top: 100px;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 850px;
  text-align: center;
  overflow: hidden;
  color: #fff;
  background-color: #03a99d;

  .summary {
    position: relative;
    transform: translateX(-26%);

    h3 {
      font-size: 120px;
      color: #fff;
      font-weight: bolder;
      line-height: 144px;
    }

    .tags {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 20px auto 0;
      max-width: 690px;

      li {
        margin: 10px;
        padding: 0 18px;
        font-size: 22px;
        line-height: 38px;
        border: 2px solid #fff;
        border-radius: 6px;
      }
    }
  }

  .img {
    position: absolute;
    z-index: 1;
    transform: translate(420px, 185px);
  }
}

.content {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  .cover {
    width: 290px;
  }

  .img {
    display: block;
    width: 100%;
    height: auto;
  }

  .col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 316px;
  }

  .inset {
    margin: -32px 0 32px;
    width: 300px;
  }

  .item {
    margin-bottom: 28px;
    font-size: 20px;
    line-height: 24px;
    text-align: justify;

    h4 {
      margin-bottom: 8px;
      font-size: 26px;
      line-height: 36px;
    }

    .tip {
      opacity: 0.6;
    }
  }
}

@media only screen and (max-width: 500px) {
  .layout {
    padding-top: 80px;
    padding-bottom: 80px;

    h3 {
      font-size: 22px;
      line-height: 30px;
    }
  }

  .banner {
    display: flex;
    height: auto;
    min-height: 100vh;
    padding: 100px 0 60px;
    flex-direction: column-reverse;

    .summary {
      margin-top: -40px;
      transform: none;
      z-index: 5;

      h3 {
        font-size: 30px;
        line-height: 32px;
      }

      .tags {
        padding: 20px 0 0;

        li {
          margin: 3px;
          padding: 0 6px;
          font-size: 12px;
          line-height: 18px;
          border: 1px solid #fff;
          border-radius: 4px;
        }
      }
    }

    .img {
      position: static;
      transform: none;
      width: 100%;
      height: auto;
    }
  }

  .content {
    margin-top: 32px;
    display: block;

    .cover {
      margin: 0 auto 32px;
      width: 150px;
    }

    .col {
      padding: 0 10px;
      width: 100%;
    }

    .inset {
      margin: 0 auto 32px;
      width: 300px;
    }

    .item {
      margin-bottom: 32px;
      font-size: 14px;
      line-height: 20px;
      text-align: justify;

      h4 {
        margin-bottom: 4px;
        font-size: 16px;
        line-height: 26px;
      }

      .tip {
        opacity: 0.6;
      }
    }
  }
}
</style>